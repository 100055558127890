<template>
    <div>
        <top title="惠医保隐私协议" v-if="show" />
        <div class="box">
        
            <p style="text-indent:24.0000pt; text-align:justify;"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>惠医保</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">(<font>以下或称&ldquo;我们</font><font face="Calibri">&quot;)</font><font>注重保护您个人信息及个人隐私。本隐私政策解释了用户个人信息</font><font face="Calibri">(</font><font>以下或称&ldquo;信息&rdquo;</font><font face="Calibri">)</font><font>收集和使用的有关情况</font><font face="Calibri">,</font><font>本隐私政策适用于</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>惠医保</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>向您提供的所有相关服务。如果您不同意本隐私政策的任何内容</font>,<font>您应立即停止使用</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>惠医保服务</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>。当您使用</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>惠医保</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>提供的任一服务时</font>,<font>即表示您已同意我们按照本隐私政策来合法使用和保护您的个人信息。</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold"><font>一、</font></span></span></span></span></b><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold"><font>我们如何收集和使用您的个人数据</font></span></span></span></span></b></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold">1.1<font>个人数据的范围</font></span></span></span></span></b></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>个人数据是指单独使用或结合其他信息使用时能够确定个人身份的信息</font>,<font>包括但不限于</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>：</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">1.) </span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>在您注册我们时</font>,<font>您提供的个人数据</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">2.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>我们因为您访问和使用我们的网站网页或服务而接收并记录的您的相关信息</font>,<font>包括但不限于您访问的网页记录、访问日期和时间、使用的浏览器的信息</font><font face="Calibri">(</font><font>类型、时区、插件、语言、字体、 </font><font face="Calibri">canvas</font><font>图片渲染哈希值、</font><font face="Calibri">webg L</font><font>图片渲染哈希值等</font><font face="Calibri">)</font><font>、您在页面上的鼠标和键盘操作信息、软硬件特征、网络链接信息</font><font face="Calibri">(IP</font><font>地址、端口、网络协议等</font><font face="Calibri">)</font><font>等数据</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">3.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>您与我们互动</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>、</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>因使用服务或获得支持和帮助时提供的个人数据</font>;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">4</span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">.</span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>我们通过合法途径从商业伙伴处或第三方取得的数据。例如在法律允许的情况下</font>,<font>我们会从公用和商用第三方来源获取有关数据来支持我们的服务。</font></span></span></span></span></span></span></span></p>

            <p style="text-indent:21.0000pt; text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>通常情况下</font>,<font>您不需要提供个人数据就可以浏览我们网站</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>或公众号</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>的对外公开信息。为了给您提供服务</font>,<font>我们会按照本隐私政策的规定保护、使用和披露您的个人数据。为达到服务之目的</font><font face="Calibri">,</font><font>如果您以自愿填写的方式或根据要求提供注册或</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>订</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>阅所需的姓名、性别、证件种类及证件号、出生年月日、国籍、电子</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>邮</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>件信箱、电话、联系地址和</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>邮政编码</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>所希望提供的服务或喜好信息、客戶以及其他类似的个人数据</font>,<font>则表示您已经了解并接受我们依照本隐私政策对您的个人数据的收集、保护和使用。</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold">1.2<font>非个人数据范围</font></span></span></span></span></b></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>非个人数据包括但不限于</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>：</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">1.</span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>在使用我们的网站提供服务时您所发布、公布的信息</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>；</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">2.</span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>我们已对您采取的包括但不限于信用评价、违反法律法规规定或违反我们政策的处理措施等相关信息</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>；</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">3.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>我们还可能收集和使用非识别性数据。非识别性数据是指无法单独用于确定个人身份的数据。例如</font>,<font>我们会收集汇总的统计数据</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>（</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>包括但不限于访问量等</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>）</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>。我们收集此数据的目的在于了解您如何使用自己的网站</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>或公众号</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>、产品和服务。借此</font>,<font>我们可以改善自己的服务</font><font face="Calibri">,</font><font>更好地满足客戶需求。我们可能会自行决定出于其他目的的收集、使用、处理、转移或披露非识别性数据。我们会尽力隔离您的个人数据和非识别性数据</font><font face="Calibri">,</font><font>并单独使用这两种数据。如果个人数据摻杂了非识别性数据</font><font face="Calibri">,</font><font>依旧会被视作个人数据处理</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>。</font></span></span></span></span></span></span></span></p>

            <p style="text-indent:21.0000pt; text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>未经我们事先同意</font>,<font>您不应向我们提供我们未明确要求的、可能致使我们在本政策之外被附加額外法律义务的</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>信息。</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold">1.</span></span></span></span></b><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold">3<font>个人数据的使用</font></span></span></span></span></b></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>我们可能将您的个人数据用于以下目的</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>：</font></span></span></span></span></span></span></span></p>

            <ol>
                <li style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>履行计单</font>;<font>交付、激活</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>、</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>验证产品或服务</font>;<font>应您的要求进行变更</font><font face="Calibri">;</font><font>以及提供技术支持和服务</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></li>
                <li style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>向您发送重要通知</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></li>
                <li style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>开展内部审计、数据分析和研究</font>,<font>改善我们的产品和服务</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></li>
                <li style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>分析业务运营效率并衡量市场份额</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></li>
            </ol>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(5)<font>改善我们的防损和反欺诈计划</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(6)<font>遵从和执行适用的法律法规要求</font><font face="Calibri">,</font><font>相关的行业标准或我们的政策</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(7)<font>其它为向您提供服务而需要使用您个人数据的情形</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>；</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold"><font>二、</font></span></span></span></span></b><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold"><font>我们如何披露您的个人数据</font></span></span></span></span></b></span></span></span></span></p>

            <p style="text-indent:21.0000pt; text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>未经您事先同意</font>,<font>我们承诺不会出售您的个人数据</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>。</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>我们会在法律允许的范围內根据本政策使用您提供的个人数据。但我们可能会根据法律法规、司法机构、执法机构、监管机构或政府部门的要求向这些部门提供您的个人数据</font>;<font>或在诉讼、仲裁等法律或争议解决程序中披露</font><font face="Calibri">;</font><font>或在我们认为有必要这样做来保护我们、客户或公众利益时</font><font face="Calibri">,</font><font>也可能在尽可能小的范围內公开某些个人数据。在您提供个人数据时</font><font face="Calibri">,</font><font>您理解并同意我们将在某些情形中使用或向第三方提供您的个人数据</font><font face="Calibri">,</font><font>包括但不限于如下情形</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>：</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">1.) </span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>根据法律法规的有关规定</font>,<font>或者应行政或司法执法及监管机构的要求</font><font face="Calibri">,</font><font>向第三方或者行政机关、司法机构、执法机构或监管机构披露</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">2.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>如您出现违反所适用的法律、法规或我们服务协议或相关政策的情况</font>,<font>需要向第三方披露</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">3.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>为向您提供或因使用您所订购或要求的产品和服务时</font>,<font>而需要和第三方披露</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">4</span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>其它我们根据法律、法规或者网站政策认为合适的披露例如出于执行条款与条件或为阻止人身、财产损失或非法行为的目的</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">5.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>除非得到您的许可</font>,<font>或本政策约定的其它情形</font><font face="Calibri">,</font><font>或因该第三方作为我们的服务提供商</font><font face="Calibri">/</font><font>分包商</font><font face="Calibri">/</font><font>授权中心向您提供服务</font><font face="Calibri">,</font><font>我们不会向无关第三方提供、出售或出租您的个人数据</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>；</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">6.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>网络链接信息可能被用于网络攻击检测</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>；</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">7.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>在诉讼、仲裁等法律或争议解决程序中</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>；</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold"><font>三、用户信息的保护</font></span></span></span></span></b></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">1.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>我们重视个人数据的安全。我们采用适当的物理管理和技术保障措施来保护您的个人数据不被未经授权访问、披露、使用、修改、损坏或丢失。例如</font>,<font>我们会使用加密技术确保数据的机密性</font><font face="Calibri">;</font><font>我们会使用保护机制防止数据遭到恶意攻击</font><font face="Calibri">;</font><font>我们会部署访问控制机制</font><font face="Calibri">,</font><font>确保只有授权人员才可访问个人数据</font><font face="Calibri">;</font><font>以及我们会举办安全和隐私保护培训课程</font><font face="Calibri">,</font><font>加强员工对于保护个人数据重要性的认识我们将会在达成本政策所述目的所需的期限内保留您的个人数据</font><font face="Calibri">,</font><font>除非需要延长保留期或受到法律的允许。我们会尽力保护您的个人数据</font><font face="Calibri">,</font><font>但是请注意任何安全措施都无法做到无懈可击</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">2.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>尽管有前述安全措施</font>,<font>但妥善保管您的账户及密码及其他信息的安全是您的责任和义务</font><font face="Calibri">,</font><font>因您未能保管好账户和密码而导致的法律责任由您全部承担</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">3.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>如果发现您账号或密码被他人非法使用或有使用异常的情况的</font>,<font>应及时通知我们</font><font face="Calibri">,</font><font>您可要求我们采取措施暂停您账户的登录和使用</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">4.)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>如不幸发生信息泄露等安全事件</font>,<font>我们将依照适用的法律法规的要求</font><font face="Calibri">,</font><font>向您和或主管机关告知数据泄露事件的相关信息。如果因完全归因于我们的原因造成您的信息泄露</font><font face="Calibri">,</font><font>在我们确认相关数据泄露事件及根因后</font><font face="Calibri">,</font><font>我们将尽努力第一时间向您发送数据泄露的通知</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>。</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:14px"><span ><span style="line-height:150%"><span style="font-weight:bold"><font>四、您发布内容规范</font></span></span></span></span></b></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">1<font>、本条所述内容是指您使用服务的过程中所制作、上载、复制、发布、传播的任何內容</font><font face="Calibri">,</font><font>包括但不限于账号头像、名称、用户说明等注册信息及认证资料</font><font face="Calibri">,</font><font>或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面</font><font face="Calibri">,</font><font>以及其他使用账号或本服务所产生的内容。</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">2<font>、您不得利用账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的內容</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">:</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(1) </span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>反对宪法所确定的基本原则的</font>;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(2)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>危害国家安全</font>,<font>泄露国家秘密</font><font face="Calibri">,</font><font>颠覆国家政权</font><font face="Calibri">,</font><font>破坏国家统一的</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(3)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>损害国家荣誉和利益的</font>;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(4)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>煽动民族仇恨、民族歧视</font>,<font>破坏民族团结的</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(5)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>破坏国家宗教政策</font>,<font>宣扬邪教和封建迷信的</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(6)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>散布谣言</font>,<font>扰乱社会秩序</font><font face="Calibri">,</font><font>破坏社会稳定的</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(</span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">7)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的</font>;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(8)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>侮辱或者诽谤他人</font>,<font>侵害他人合法权益的</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(9)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>含有法律、行政法规禁止的其他內容的信息。</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">3<font>、您不得利用账号或本服务制作、上载、复制、发布、传播如下干扰&ldquo;服务&rdquo;正常运营</font><font face="Calibri">,</font><font>以及侵犯其他用户或第三方机构合法权益的内容</font><font face="Calibri">:</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(1) </span></span></span><span style="font-size:14px"><span ><span style="line-height:150%"><font>含有任何性或性暗示的</font>;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(2)</span></span></span>&nbsp;<span style="font-size:14px"><span ><span style="line-height:150%"><font>含有辱骂、恐吓、威胁内容的</font>;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(</span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">3)<font>含有骚扰、垃圾广告、恶意信息、诱骗信息的</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(4)<font>涉及他人隐私、信息或资料的</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(5)<font>侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的</font><font face="Calibri">;</font></span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">(</span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">6)<font>含有其他干扰本服务正常运营和侵犯其他您或第三方合法权益内容的信息</font></span></span></span><span style="font-size:14px"><span ><span style="line-height:150%">;</span></span></span></span></span></span></span></p>

            <p style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:14.0000pt"><span ><span style="line-height:150%"><span style="font-weight:bold"><font>五、使用</font> Cookie</span></span></span></span></b></span></span></span></span></p>

            <p style="text-indent:21.0000pt; text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%">Cookie<font>是由网页服务器存放在您的访问设备上的文本文件。指定给您的 </font><font face="Calibri">Cookie</font><font>是唯一的</font><font face="Calibri">,</font><font>它只能被将</font><font face="Calibri">Cookie</font><font>发布给您的域中的</font><font face="Calibri">Web</font><font>服务器读取。我们使用</font><font face="Calibri">Cookie</font><font>来帮助您实现您的联机体验的个性化</font><font face="Calibri">,</font><font>使您在我们及其关联方获得更轻松的访问体验。例如</font><font face="Calibri">, Cookie</font><font>会帮助您在后续访问我们网站时调用您的信息</font><font face="Calibri">,</font><font>简化记录您填写信息</font><font face="Calibri">(</font><font>例如一键登录等</font><font face="Calibri">)</font><font>的流程</font><font face="Calibri">;</font><font>为您提供安全购物的偏好设置</font><font face="Calibri">;</font><font>帮助您优化对广告的选择与互动</font><font face="Calibri">;</font><font>保护您的数据安全等。您有权接受或拒绝 </font><font face="Calibri">Cookie</font><font>。大多数浏览器会自动接受 </font><font face="Calibri">Cookie,</font><font>但您通常可根据自己的需要来修改浏览器的设置以拒绝 </font><font face="Calibri">Cookie</font><font>。如果选择拒绝</font><font face="Calibri">Cookie,</font><font>那么您可能无法完全体验所访问的我们网站或某些服务的全部功能。</font></span></span></span></span></span></span></span></p>

            <ul>
                <li style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:11.0000pt"><span ><span style="line-height:150%"><span style="font-weight:bold"><font>未成年人的信息保护</font></span></span></span></span></b></span></span></span></span></li>
            </ul>

            <p style="text-indent:21.0000pt; text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>我们非常重视对未成年人信息的保护。若您是</font>18<font>周岁以下的未成年人</font><font face="Calibri">,</font><font>在使用我们服务前</font><font face="Calibri">,</font><font>应事先取得您家长或法定监护人的书面同意。我们根据国家相关法律法规的规定保护未成年人的信息。</font></span></span></span></span></span></span></span></p>

            <ul>
                <li style="text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><b><span style="font-size:11.0000pt"><span ><span style="line-height:150%"><span style="font-weight:bold"><font>通知和修订</font></span></span></span></span></b></span></span></span></span></li>
            </ul>

            <p style="text-indent:21.0000pt; text-align:justify"><span style="font-size:14px"><span style="text-autospace:ideograph-numeric"><span style="line-height:150%"><span ><span style="font-size:14px"><span ><span style="line-height:150%"><font>为给你提供更好的服务</font>,<font>我们的业务将不时变化</font><font face="Calibri">,</font><font>本隐私政策也将随之调整。我们会在我们网站、移动端上发出更新版本并提醒您相关内容的更新</font><font face="Calibri">,</font><font>也请您访问我们以便及时了解最新的隐私政策。如果您对于本隐私政策或在使用我们服务时对于您的信息或隐私情况有任何问题</font><font face="Calibri">,</font><font>请联系我们客服并作充分描述</font><font face="Calibri">,</font><font>我们将尽力解决。</font></span></span></span></span></span></span></span></p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            show:false
        }
    },
    mounted(){
        this.show = this.$route.query.show || false
    }
}
</script>

<style scoped lang="scss">
.box{
    padding: 0 vw(28) vw(24);
    line-height: vw(44);
    p{
        line-height: vw(44);
    }
}
</style>